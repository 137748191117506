@keyframes loading-img {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loading-img {
  animation: loading-img infinite 1s linear;
}
